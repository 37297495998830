import React from "react"
import LazyLoad from "react-lazyload"

type Props = {|
  prismicData: Object,
  countryCode: string,
|}

const Equipment = ({ prismicData, countryCode }: Props) => {
  const { equipment_title = "", equipment_description = "" } = prismicData || {}

  const equipmentPhoto = (prismicData &&
    prismicData[`${countryCode}_equipment_photo`]) || {
    url: "",
    alt: "",
    mobile: {
      url: "",
      alt: "",
    },
  }

  const equipmentUrl =
    (prismicData && prismicData[`${countryCode}_equipment_url`]) || {}

  const equipmentPhotoUrl = equipmentPhoto.url
  const equipmentPhotoMobile =
    equipmentPhoto.thumbnails && equipmentPhoto.thumbnails.mobile

  return (
    <section className="flex flex-col bg-gray-120 items-center text-center">
      <div className="m-auto md:max-w-736 px-15 md:px-0 py-48">
        <h4 className="text-6xl font-secondary pb-8 leading-4none md:text-9xl md:pb-32">
          {equipment_title.text}
        </h4>
        <p className="m-auto text-base font-base-light pb-32 leading-snug md:text-2lg md:max-w-600">
          {equipment_description.text}
        </p>

        <a href={equipmentUrl.url}>
          <LazyLoad once>
            <picture>
              <source srcSet={equipmentPhotoUrl} media="(min-width: 640px)" />
              <source
                srcSet={equipmentPhotoMobile ? equipmentPhotoMobile.url : ""}
                media="(max-width: 375px)"
              />
              <img
                alt={equipmentPhoto.alt || ""}
                src={equipmentPhotoUrl}
                className="h-full w-full object-cover"
                data-cy={`${countryCode}-equipment-image`}
              />
            </picture>
          </LazyLoad>
        </a>
      </div>
    </section>
  )
}

export default Equipment
